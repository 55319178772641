<template>
    <Modal v-model="isShow" width="800" :footer-hide="true">
        <Row>
            <i-col span="12" class="dispatch-bottom-data-title">
                <h2>站点作业任务进程图</h2>
            </i-col>
        </Row>
        <Divider dashed style="margin: 5px 0;"/>
        <Table size="small" stripe :data="tableList" :columns="tableColumns"></Table>
        <div class="paging_style">
            <Page size="small" show-total show-elevator :total="totalRow" :page-size="query.pageSize" @on-change="handlePageChange"></Page>
        </div>
    </Modal>
</template>

<script>
import { getTaskStationProgressPage } from '@/api/msp/taskwork'

export default {
  data () {
    return ({
      isShow: false,
      query: {
        pageNumber: 1,
        pageSize: 10,
        actionDate: ''
      },
      tableList: [],
      totalRow: 0,
      tableColumns: [
        { title: '站点', width: 150, key: 'domainName' },
        {
          title: '作业进度(已完成/已质检/任务数)',
          render: (h, params) => {
            let taskContent = ''
            let flag = false
            params.row.taskitemTypeStatisticList.forEach(element => {
              if (flag) {
                taskContent += '、'
              }
              taskContent += element.taskitemTypeName
              taskContent += '('
              taskContent += element.finishCount + '/'
              taskContent += element.quantityTestCount + '/'
              taskContent += element.totalCount
              taskContent += ')'
              flag = true
            })

            return h('span', taskContent)
          }
        }
      ]
    })
  },
  methods: {
    initPageData () {
      getTaskStationProgressPage(this.query).then(res => {
        this.tableList = res.list
        this.totalRow = res.totalRow
      })
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    }
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    },
    stationProcess () {
      return this.$store.state.dispatch.stationProcess
    }
  },
  watch: {
    stationProcess (val) {
      if (val) {
        this.isShow = true
        this.query.pageNumber = 1
        this.query.actionDate = this.actionDate
        this.initPageData()
      }
    },
    isShow (val) {
      if (!val) {
        this.$store.commit('set_dispatch_show_stationProcess', false)
      }
    }
  }
}
</script>
