import request from '@/utils/requestV2'
const qs = require('qs')

// 获取品牌进度
export function getBrandProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getbrandprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取打卡数据
export function getCheckinList (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getcheckinlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取站点进度
export function getDomainProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getdomainprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取调度信息列表
export function getManageMsgPage (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getmanagemsgpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 作业总览
export function getOverAll (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getoverall',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取执行中/已完成/已质检的资源主键
export function getResourceIdList (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getresourceidlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取资源类型的进度
export function getResourceTypeProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getresourcetypeprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取站点LBS详情
export function getStationInfoList (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getstationinfolist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取(站点)小组进度
export function getWorkGroupProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getworkgroupprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取站点完成进程数据
export function getFinishProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/getfinishprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取履约进程
export function getTaskProgressPage (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/gettaskprogresspage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取站点作业进程
export function getTaskStationProgressPage (data) {
  return request({
    url: '/ooh-msp/v2/taskwork/gettaskstationprogresspage',
    method: 'post',
    data: qs.stringify(data)
  })
}
